#multipliersDiv {
  margin-top: 30px;
}

.help {
  margin-top: 30px;
  line-height: 1.5rem;

  h2 {
    line-height: 2rem;
  }

  & > * {
    color: $color-contrast-high;
  }
}