/**
 * Basic typography style for copy text
 */
 
 #maxLiftResultElem {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  margin-top: 30px;
 }

 #calorieTargetResultElem {
  line-height: 1.5rem;
  font-weight: 700;
  margin: 0;
  margin-top: 30px;
 }