.max-lifts {
  // padding: 20px 0;
}

#percentagesDiv {
  margin-top: 30px;

  p {
    max-width: 750px;
    line-height: 1.4rem;
    font-weight: 300;
  }
}