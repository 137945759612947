// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.container {
  max-width: $max-width;
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;

  @include respond-to('small') {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.table-responsive {
  overflow-x: auto;
}